import React, { useState } from 'react';
import { addDoc, collection } from 'firebase/firestore';
import { db } from '../../firebase/auth';
import { getAuth } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';

const CrearGrupo = ({ onGroupCreated }) => {
  const initialFormData = {
    groupName: '',
    institution: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        const teacherEmail = user.email;

        // Crear el grupo con campos adicionales
        await addDoc(collection(db, 'groups'), {
          ...formData,
          students: [],
          teacherEmail: teacherEmail,
        });

        setFormSubmitted(true);
        setFormData(initialFormData);
        setTimeout(() => setFormSubmitted(false), 3000);

        // Notificar que el grupo ha sido creado y navegar a WelcomeProfesor
        onGroupCreated();
        navigate('/welcome-profesor');
      } else {
        console.error('No hay un usuario autenticado.');
      }
    } catch (error) {
      console.error('Error al enviar el formulario:', error);
    }
  };

  return (
    <div className="w-full sm:w-3/4 md:w-1/2 lg:w-1/2 xl:w-1/2 max-w-md mx-auto sm:max-w-sm md:max-w-md lg:max-w-lg xl:max-w-xl bg-white shadow-md rounded-lg p-6 mt-10 py-10">
      <h2 className="text-2xl font-bold mb-4">Crear Grupo</h2>
      {!formSubmitted ? (
        <form onSubmit={handleSubmit}>
          <div className="mb-4 relative">
            <div className="flex items-center">
              <span className="text-red-500">*</span>
              <label className="block text-gray-700 ml-1">Nombre del Grupo</label>
            </div>
            <input
              type="text"
              name="groupName"
              value={formData.groupName}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg mt-2"
              required
            />
          </div>
          <div className="mb-4 relative">
            <div className="flex items-center">
              <span className="text-red-500">*</span>
              <label className="block text-gray-700 ml-1">Institución</label>
            </div>
            <input
              type="text"
              name="institution"
              value={formData.institution}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded-lg mt-2"
              required
            />
          </div>
          <button type="submit" className="w-full bg-custom-blue text-white py-2 px-4 rounded-lg hover:bg-blue-700">Enviar</button>
        </form>
      ) : (
        <div>
          <div className="mb-4 p-4 bg-green-100 text-green-700 border border-green-300 rounded-lg">
          Grupo creado exitosamente
          </div>
          <button onClick={ () => navigate('/welcome-profesor') } className="w-full bg-custom-blue text-white py-2 px-4 rounded-lg hover:bg-blue-700">
            Regresar
          </button>
        </div>
      )}
    </div>
  );
};

export default CrearGrupo;