import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, getDocs, query, orderBy, doc, setDoc } from 'firebase/firestore';
import { db } from '../../firebase/auth'; 
import { getAuth } from 'firebase/auth';
import Loading from './Loading';

const QuestionComponent = () => {
    const [questions, setQuestions] = useState([]);
    const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
    const [isCompleted, setIsCompleted] = useState(false);
    const [answers, setAnswers] = useState([]);
    const navigate = useNavigate();
    const auth = getAuth();
    const [animationDirection, setAnimationDirection] = useState('');

    useEffect(() => {
        const fetchQuestions = async () => {
            try {
                const q = query(collection(db, 'questions'), orderBy('QuestionPosition'));
                const querySnapshot = await getDocs(q);
                const questionsData = querySnapshot.docs.map(doc => ({
                    question: doc.data().QuestionText,
                    options: ["Sí", "No"]
                }));
                setQuestions(questionsData);
                setAnswers(Array(questionsData.length).fill(null));
            } catch (error) {
                console.error("Error fetching questions: ", error);
                navigate('/login');
            }
        };
    
        fetchQuestions();
    }, [navigate]);

    const handleNext = () => {
        setAnimationDirection('left');
        setTimeout(() => {
            if (currentQuestionIndex < questions.length - 1) {
                setCurrentQuestionIndex(currentQuestionIndex + 1);
            } else {
                setIsCompleted(true);
                saveAnswers(answers);
            }
            setAnimationDirection('');
        }, 300); 
    };


    const handlePrevious = () => {
        setAnimationDirection('right');
        setTimeout(() => {
            if (currentQuestionIndex > 0) {
                setCurrentQuestionIndex(currentQuestionIndex - 1);
            } else {
                navigate('/home');
            }
            setAnimationDirection('');
        }, 300); 
    };


    const handleOptionChange = (index) => {
        setAnimationDirection('left');
        setTimeout(() => {
            const newAnswers = [...answers];
            newAnswers[currentQuestionIndex] = questions[currentQuestionIndex].options[index];
            setAnswers(newAnswers);

            // Avanza a la siguiente pregunta o finaliza si es la última
            if (currentQuestionIndex < questions.length - 1) {
                setCurrentQuestionIndex(currentQuestionIndex + 1);
            } else {
                setIsCompleted(true);
                saveAnswers(newAnswers);
            }
            setAnimationDirection('');
        }, 300); 
    };

    const saveAnswers = async (answers) => {
        const user = auth.currentUser;
        if (user) {
            try {
                const userAnswersRef = doc(db, 'users', user.uid, 'answers', 'latest');
                await setDoc(userAnswersRef, {
                    answers,
                    timestamp: new Date()
                });
            } catch (error) {
                console.error("Error adding document: ", error);
            }
        } else {
            console.error("User is not authenticated");
        }
    };

    const handleExit = () => {
        navigate('/home');
    };

    if (isCompleted) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="flex flex-col items-center w-full max-w-md p-6 bg-white rounded-lg shadow-md">
                    <h2 className="text-2xl font-bold mb-4">Felicidades por responder</h2>
                    <div className="text-left max-h-96 overflow-y-auto">
                        <h3 className="text-xl font-semibold mb-2">Resumen de tus respuestas:</h3>
                        <ul className="list-disc pl-5">
                            {questions.map((q, index) => (
                                <li key={index} className="mb-1">
                                    <strong>{q.question}</strong>: {answers[index]}
                                </li>
                            ))}
                        </ul>
                        <p className="mt-4">Marca de tiempo: {new Date().toLocaleString()}</p>
                    </div>
                    <button
                        onClick={handleExit}
                        className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700"
                    >
                        Salir
                    </button>
                </div>
            </div>
        );
    }

    if (questions.length === 0) {
        return <Loading/>;
    }

    const { question, options } = questions[currentQuestionIndex];

    return (
        <div className="flex justify-center h-auto mt-6">
            <div className="flex flex-col items-center w-full max-w-md p-6 bg-white rounded-lg shadow-md">
                <div className={`transition-all duration-300 transform ${animationDirection === 'left' ? '-translate-x-full' : animationDirection === 'right' ? 'translate-x-full' : ''}`}>
                    <h2 className="text-2xl font-bold mb-4">{question}</h2>
                    <div className="flex flex-col space-y-4 mb-6">
                        {options.map((option, index) => (
                            <label key={index} className="flex items-center">
                                <input
                                    type="radio"
                                    name={`option-${currentQuestionIndex}`}
                                    className="mr-2"
                                    checked={answers[currentQuestionIndex] === option}
                                    onChange={() => handleOptionChange(index)}
                                />
                                <span className="text-xl">{option}</span>
                            </label>
                        ))}
                    </div>
                    <div className="mb-4 text-lg">
                        Pregunta {currentQuestionIndex + 1} de {questions.length}
                    </div>
                    <div className="flex space-x-4">
                        <button
                            onClick={handlePrevious}
                            className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-700"
                        >
                            &#x2190;Atrás
                        </button>
                        <button
                            onClick={handleNext}
                            className={<code>px-4 py-2 text-white rounded hover:bg-blue-700 ${!answers[currentQuestionIndex] ? 'bg-gray-400 cursor-not-allowed' : 'bg-blue-500'}</code>}
                            disabled={!answers[currentQuestionIndex]}
                        >
                            {currentQuestionIndex === questions.length - 1 ? 'Finalizar' : 'Adelante→'}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuestionComponent;