import React from 'react';
import { validateAndUpdateTeacher } from '../../firebase/auth'; 
import { useNavigate } from 'react-router-dom';

const EmailVerification = ({ teacherEmail, setTeacherEmail, setIsValidated, setError, currentUser, handleSignOut, error }) => {
  const navigate = useNavigate();
  const handleVerifyEmail = async () => {
    try {
      const isValid = await validateAndUpdateTeacher(currentUser.uid, teacherEmail, navigate);
      console.log('Is teacher email valid?', isValid);
      if (isValid) {
        setIsValidated(true);
      } else {
        setError('Correo electrónico no válido');
      }
    } catch (err) {
      console.error('Error verifying Teacher email:', err);
      setError('Error al verificar el correo electrónico');
    }
  };

  return (
    <div className='flex flex-col items-center w-full top-10 justify-center text-2xl font-bold pt-10 text-center'>
      <h1>Verificación de correo electrónico del profesor</h1>
      <input
        type='email'
        value={teacherEmail || ''}
        onChange={(e) => setTeacherEmail(e.target.value)}
        placeholder='Ingrese el correo electrónico de su profesor'
        className='text-xl px-4 py-2 mt-4 mb-2 border rounded'
      />
      <button
        onClick={handleVerifyEmail}
        className='text-xl px-4 py-2 bg-custom-blue text-white rounded hover:bg-blue-700 mt-2'
      >
        Verificar
      </button>
      <button
        onClick={handleSignOut}
        className='text-xl mt-10 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-700'
      >
        Cerrar sesión
      </button>
      {error && <p className='text-red-500 mt-2'>{error}</p>}
    </div>
  );
};

export default EmailVerification;